<template>
  <div>
    <el-select
      class="max-fill"
      v-if="format==='dropDown'"
      size="medium"
      v-model="value"
      placeholder="请选择选项"
    >
      <el-option
        v-for="(op, index) in option.split(',')"
        :key="index"
        :label="op"
        :value="index"
        >{{ op }}</el-option
      >
    </el-select>
    <el-radio-group v-else>
      <el-radio v-for="(op, index) in option.split(',')" :key="index" :label="op">{{
        op
      }}</el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    format: {
      type: String,
      default: 'dropDown',
    },
    option: {
      type: String ,
    },
  },
  data() {
    return {
      value: 0,
    };
  },
};
</script>

